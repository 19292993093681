<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="家庭编号：">
					<el-autocomplete
						v-model.trim="formInline.identifier"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="100"
				:is-show-selection="false"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'familyInfo',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			formInline: {
				identifier: '',
			},
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '家庭编号',
					prop: 'identifier',
					formatter: (row) => {
						return row.identifier || '-';
					},
				},
				{
					label: '家庭成员',
					prop: 'personList',
					formatter: (row) => {
						return row.personList?.map((v) => v.title).join(',') || '-';
					},
				},
				{
					label: '乡镇/街道街道名称',
					prop: 'regionDtoList',
					formatter: (row) => {
						return row.regionDtoList && row.regionDtoList.length > 3 ? row.regionDtoList[3].title : '-';
					},
				},
				{
					label: '村委会名称',
					prop: 'regionDtoList',
					formatter: (row) => {
						return row.regionDtoList && row.regionDtoList.length > 4 ? row.regionDtoList[4].title : '-';
					},
				},
				{
					label: '创建时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
			],
			options: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList('');
		},
		handleSelect(subInput) {
			this.formInline.identifier = subInput.identifier;
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api['Households#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.identifier,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(row) {
			this.$router.push({
				path: '/userManager/showDetails',
				query: {
					familyNumber: row.identifier,
					personList: row.personList?.length > 0 ? JSON.stringify(row.personList) : [],
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;

		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
}
.el-button--warning {
	background-color: #38b4f2;
	border-color: #38b4f2;
	color: #fff;
}
</style>
